import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'

import * as vant from 'vant';

import 'vant/lib/index.css'
import '../src/assets/font/index.css'

const app = createApp(App)

app.use(router).use(vant).mount('#app')



