import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@layouts/mediaLayout'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        meta: { title: 'Guang' },
        components: {
          pc: () => import('@pages/home/pc/index.vue'),
          mobile: () => import('@pages/home/mobile/index.vue'),
        },
      },
    ],
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
router.beforeEach((to, from, next) => {
  document.title = to.meta?.title ? to.meta.title : 'Guang'
  next()
})

export default router
